/* eslint-disable */
import axios from 'axios';
import localStorageMethods from './localStorage';
import store from '../store/index';
import utils from './utils';

const baseURL = process.env.VUE_APP_BASEURL;

function googleLoginHandler(window) {
  setGoogleAccounts(window);
  const button = getGoogleButton(window.document);
  addButtonBehavior(button);
}

function setGoogleAccounts(window) {
  window.onload = function () {
    google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_APP_ID,
      callback: handleGoogleUser,
    });
  };
}

function handleGoogleUser(user) {
  const token = user.credential;
  const cart = localStorageMethods.GetCart();
  if (cart.Products.length > 0) {
    const products = cart.Products;
    const cartProducts = products.map((data) => {
      const newO = {
        Configurations: data.Configurations.map((x) => x.Id),
        ProductId: data.ProductId,
        ...(store.state.locationId ? { LocationId: store.state.locationId } : ''),
        Quantity: data.Quantity,
      };
      return newO;
    });
    const searchParams = {
      Token: token,
      ...(store.state.locationId ? { LocationId: store.state.locationId } : ''),
    };
    axios
      .put(`${baseURL}/api/Auth/login-google?${new URLSearchParams(searchParams)}`, cartProducts)
      .then((response) => {
        if (response.data.Token) {
          localStorageMethods.ClearCart();
          localStorage.setItem('user', JSON.stringify(response.data));
          window.location.reload();
        }
      })
      .catch(() => {
        utils.ToastNotify('error', 'Something went wrong');
      });
  } else {
    const searchParams = {
      Token: token,
      ...(store.state.locationId ? { LocationId: store.state.locationId } : ''),
    };
    axios
      .put(`${baseURL}/api/Auth/login-google?${new URLSearchParams(searchParams)}`, [])
      .then((response) => {
        if (response.data.Token) {
          localStorageMethods.ClearCart();
          localStorage.setItem('user', JSON.stringify(response.data));
          window.location.reload();
        }
      })
      .catch(() => {
        utils.ToastNotify('error', 'Something went wrong');
      });
  }
}

function getGoogleButton(document) {
  return document.getElementById('google');
}

function addButtonBehavior(button) {
  button.onclick = function () {
    deleteCookie('g_state');
    google.accounts.id.prompt();
  };
}

function deleteCookie(name) {
  const expiration = '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = name + expiration;
}

export default googleLoginHandler;
