<template>
  <Form :validation-schema="schema" @submit="handleLogin" ref="logInModalRef" v-slot="{ errors }">
    <div
      class="modal fade"
      id="logInModal"
      tabindex="-1"
      aria-labelledby="logInModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="heading-06 text-neutral-07 text-center mb-32px">Autentificare</div>
          <div class="modal-body">
            <div class="mb-3" :class="{ 'invalid-input': errors.email }">
              <label for="email" class="text-neutral-07 text-button-02 mb-2">Adresa de email</label>
              <Field
                v-model="user.Email"
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                class="form-control"
              />
              <ErrorMessage name="email" class="error-message" />
            </div>
            <div class="mb-3" :class="{ 'invalid-input': errors.password }">
              <label for="login-password" class="text-neutral-07 text-button-02 mb-2">Parola</label>
              <div class="input-group right">
                <Field
                  v-model="user.Password"
                  :type="[showPassword ? 'text' : 'password']"
                  name="password"
                  id="login-password"
                  placeholder="Parola"
                  class="form-control"
                  autocomplete="on"
                />
                <span
                  class="input-group-text"
                  @pointerdown="showPassword = true"
                  @pointerup="showPassword = false"
                >
                  <img src="../../assets/images/icons/eye-linear-20x20.svg"  v-if="showPassword"/>
                  <img src="../../assets/images/icons/eye-slash-linear-20x20.svg" v-else/>
                </span>
              </div>
              <ErrorMessage name="password" class="error-message" />
            </div>
            <div class="form-check d-inline-block">
              <input
                v-model="user.RememberMe"
                class="form-check-input"
                type="checkbox"
                value="true"
                id="rememberMe"
              />
              <label
                class="text-button-02 ms-2 text-neutral0-07 pointer"
                for="rememberMe"
                style="line-height: 2 !important"
              >
                Păstrează-mă autentificat!
              </label>
            </div>
            <button class="button btn-medium-wider btn-primary-solid d-block w-100 mt-3">
              Autentificare
            </button>
            <div class="error-message mt-2 pt-1 text-center" v-if="errorMessage">
              Nu s-a putut realiza autentificarea.
            </div>
            <div class="row text-button-02 text-neutral-06 mt-4 mb-2">
              <div class="col pointer" @click="$emit('passwordRecovery', 'logInModal')">
                Am uitat parola
              </div>
              <div class="col-auto pointer" @click="$emit('signUp', 'logInModal')">Cont nou</div>
            </div>
          </div>
          <hr />
          <div class="mt-3">
            <p class="text-button-02 text-neutral-06">Alte optiuni de conectare:</p>
            <div class="row other-option-btn">
              <div class="col col-sm-4">
                <button
                  class="button btn-neutral-outlined justify-content-center"
                  type="button"
                  @click="facebookLogIn"
                >
                  <img src="../../assets/images/icons/facebook-20x20.svg" class="me-2" alt="..." />
                  Facebook
                </button>
              </div>
              <div class="col d-none d-sm-block"></div>
              <div class="col col-sm-4">
                <AuthButton :test="count" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Field, ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';
import AuthButton from './AuthButton.vue';
import facebook from '../../utils/facebook.service';

export default {
  name: 'LogInModal',
  emits: ['passwordRecovery', 'signUp'],
  data() {
    return {
      user: {
        RememberMe: false,
      },
      showPassword: false,
      errorMessage: '',
      count: 1,
    };
  },

  methods: {
    ClearModal() {
      this.showPassword = false;
      this.errorMessage = '';
      this.$refs.logInModalRef.resetForm();
    },
    handleLogin() {
      this.$store.state.loader = true;
      this.errorMessage = '';
      const cart = this.$localStorageMethods.GetCart();
      if (cart.Products.length > 0) {
        const test = cart.Products;
        const cartProducts = test.map((data) => {
          const newO = {
            Configurations: data.Configurations.map((x) => x.Id),
            ProductId: data.ProductId,
            ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
            Quantity: data.Quantity,
          };
          return newO;
        });
        this.user.cartProducts = cartProducts;
        if (this.$store.state.locationId) {
          this.user.LocationId = this.$store.state.locationId;
        }
        this.$store.dispatch('auth/login', this.user).then(
          () => {
            this.$localStorageMethods.ClearCart();
            if (this.$route.name === 'VerifyEmail') {
              // eslint-disable-next-line no-undef
              $('#logInModal').modal('hide');
              this.$store.state.loader = false;
              this.$router.push({ name: 'HomePage' });
            } else window.location.reload();
          },
          (error) => {
            this.$store.state.loader = false;
            this.errorMessage = error.response.data;
            // eslint-disable-next-line comma-dangle
          }
        );
      } else {
        if (this.$store.state.locationId) {
          this.user.LocationId = this.$store.state.locationId;
        }
        this.$store.dispatch('auth/login', this.user).then(
          () => {
            if (this.$route.name === 'VerifyEmail') {
              // eslint-disable-next-line no-undef
              $('#logInModal').modal('hide');
              this.errorMessage = '';
              this.$store.state.loader = false;
              this.$router.push({ name: 'HomePage' });
            } else window.location.reload();
          },
          (error) => {
            this.$store.state.loader = false;
            this.errorMessage = error.response.data;
            // eslint-disable-next-line comma-dangle
          }
        );
      }
    },
  },
  components: {
    Field,
    ErrorMessage,
    Form,
    AuthButton,
  },
  computed: {
    facebookLogIn() {
      return facebook.login;
    },
    schema() {
      return yup.object({
        email: yup.string().email('Email-ul nu este valid.').required('Email-ul este obligatoriu.'),
        password: yup.string().required('Parola este obligatorie.'),
      });
    },
  },
};
</script>

<style scoped>
hr {
  border-color: var(--blacktint-2);
}

.other-option-btn .btn-neutral-outlined {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--neutral-2);
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: var(--neutral-6);
  display: flex;
  align-items: center;
  width: 100%;
}
</style>
