/* eslint-disable no-undef */
// import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import localStorageMethods from './localStorage';
import store from '../store/index';

// const accountSubject = new BehaviorSubject(null);
const baseURL = process.env.VUE_APP_BASEURL;

async function apiAuthenticate(userId, accessToken) {
  const cart = localStorageMethods.GetCart();
  if (cart.Products.length > 0) {
    const products = cart.Products;
    const cartProducts = products.map((data) => {
      const newO = {
        Configurations: data.Configurations.map((x) => x.Id),
        ProductId: data.ProductId,
        ...(store.state.locationId ? { LocationId: store.state.locationId } : ''),
        Quantity: data.Quantity,
      };
      return newO;
    });
    // const searchParams = {
    //   Token: accessToken,
    //   UserId: userId,
    //   ...(store.state.locationId ? { LocationId: store.state.locationId } : ''),
    // };
    const locId = store.state.locationId ? store.state.locationId : -1;
    await axios
      .post(`${baseURL}/api/Auth/login-facebook/${accessToken}/${userId}/${locId}`, cartProducts)
      .then((response) => {
        if (response.data.Token) {
          localStorageMethods.ClearCart();
          localStorage.setItem('user', JSON.stringify(response.data));
          window.location.reload();
        }
      });
  } else {
    const locId = store.state.locationId ? store.state.locationId : -1;
    await axios
      .post(`${baseURL}/api/Auth/login-facebook/${accessToken}/${userId}/${locId}`, [])
      .then((response) => {
        if (response.data.Token) {
          localStorageMethods.ClearCart();
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        window.location.reload();
      })
      .catch(() => {
      });
  }
}

async function login() {
  // eslint-disable-next-line no-undef
  const { authResponse } = await new Promise(FB.login, { scope: 'email' });
  // const loginStatus = await FB.getAccessToken();
  if (!authResponse) return;
  await apiAuthenticate(authResponse.userID, authResponse.accessToken);
}

// function logout() {
//   initFacebookSdk().then(async () => {
//     FB.logout((response) => {
//       if (response.status === 'connected') {
//         window.location.href = '/';
//       } else {
//       }
//     }, {
//       access_token: await FB.getAccessToken(),
//     });
//   });
// }

export default {
  login,
  apiAuthenticate,
  // logout,
};
