<template>
  <div class="mobile-header d-sm-none">
    <div class="row align-items-center">
      <div class="col">
        <router-link :to="{ name: 'HomePage' }">
          <img
            src="/logo-delicitate.webp"
            class="logo-img"
            alt="Logo Delicitate"
            width="150"
            height="34"
          />
        </router-link>
      </div>
      <div class="col-auto">
        <div
          class="hamburger"
          :class="{ 'is-active': hamburgerOpen }"
          v-on:click="hamburgerOpen = !hamburgerOpen"
        >
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="hamburger-menu d-md-none" :class="{ 'hamburger-menu-after': hamburgerOpen }">
    <div style="padding: 24px 23px">
      <nav class="nav nav-link">
        <router-link
          v-for="(page, index) in pages"
          :key="index"
          :to="{ name: page.Name }"
          class="nav-link"
        >
          <div class="d-flex">
            <img
              :src="require(`@/assets/images/icons/mobile-pages/${page.Icon}`)"
              alt="..."
              width="20"
              height="20"
            />
            <span class="text-button-01 label">
              {{ page.Label }}
            </span>
          </div>
        </router-link>
        <router-link :to="{ name: 'Contact' }" class="nav-link">
          <div class="d-flex">
            <img
              src="../../assets/images/icons/mobile-pages/contact.svg"
              alt="..."
              width="20"
              height="20"
            />
            <span class="text-button-01 label">Contact</span>
          </div>
        </router-link>
      </nav>
      <div class="row align-items-center m-0 mt-5" v-if="this.$store.state.auth.user">
        <div class="col-6">
          <router-link :to="{ name: 'UserProfile' }" class="button btn-medium btn-primary-solid">
            <img
              src="../../assets/images/icons/mobile-pages/profile-circle-white-20x20.svg"
              class="me-2"
            />
            Profil
          </router-link>
        </div>
        <div class="col-6">
          <button type="button" class="button btn-medium btn-neutral-outlined" @click="SignOut()">
            Delogare
          </button>
        </div>
      </div>
      <div class="row align-items-center m-0 mt-5" v-else>
        <div class="col-6">
          <button
            type="button"
            class="button btn-medium btn-primary-solid"
            @click="$emit('signUp')"
          >
            Înregistrează-te
          </button>
        </div>
        <div class="col-6">
          <button
            type="button"
            class="button btn-medium btn-primary-outlined"
            @click="$emit('logIn')"
          >
            Autentificare
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--footer menu-->
  <div class="mobile-navbar-menu d-sm-none">
    <nav class="nav nav-pills nav-justified">
      <router-link
        v-for="(page, index) in pages"
        :key="index"
        :to="{ name: page.Name }"
        class="nav-link"
      >
        <div class="text-center">
          <span class="position-relative">
            <dot-products-count-component
              top="-6px"
              right="-7px"
              v-if="index + 1 === pages.length"
            />
            <img
              :src="require(`@/assets/images/icons/mobile-pages/${page.Icon}`)"
              alt="..."
              width="25"
              height="24"
            />
          </span>

          <div>
            {{ page.Label }}
          </div>
        </div>
      </router-link>
    </nav>
  </div>
</template>

<script>
import DotProductsCountComponent from '../Cart/DotProductsCountComponent.vue';
/*eslint-disable*/

export default {
  name: 'mobile-navbar',
  emits: ['logIn', 'signUp'],
  components: {
    DotProductsCountComponent,
  },
  data() {
    return {
      pages: [
        {
          Label: 'Acasă',
          Name: 'HomePage',
          Icon: 'home.svg',
        },
        {
          Label: 'Produse',
          Name: 'Products',
          Icon: 'products.svg',
        },
        {
          Label: 'Articole',
          Name: 'Blogs',
          Icon: 'blogs.svg',
        },
        {
          Label: 'Coș',
          Name: 'Cart',
          Icon: 'cart.svg',
        },
      ],
      hamburgerOpen: false,
    };
  },
  computed: {
    currentPage() {
      return this.$route.meta.label;
    },
  },
  watch: {
    $route: {
      handler() {
        this.hamburgerOpen = false;
      },
      deep: true,
      immediate: true,
    },

    hamburgerOpen: {
      handler(value) {
        value
          ? ((document.body.style.overflowY = 'hidden'), (document.body.style.position = 'fixed'))
          : ((document.body.style.overflowY = 'visible'),
            (document.body.style.position = 'relative'));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    SignOut() {
      this.$store.dispatch('auth/logout').then(() => {
        const routeData = this.$router.resolve({ name: 'HomePage' });
        window.open(routeData.href, '_self');
      });
    },
  },
};
</script>
<style scoped>
.btn-medium {
  padding: 10px 14px;
  font-size: 12px;
  width: 100%;
  text-align: center;
}
.btn-medium img {
  filter: invert(96%) sepia(96%) saturate(0%) hue-rotate(92deg) brightness(105%) contrast(104%) !important;
}

.hamburger-menu a.nav-link {
  padding: 16px 0px;
}
.hamburger-menu a:not(:last-child) {
  border-bottom: 1px solid #eaeaea;
}

.hamburger-menu .router-link-exact-active img {
  filter: invert(43%) sepia(71%) saturate(2986%) hue-rotate(314deg) brightness(82%) contrast(105%);
}
.hamburger-menu .router-link-exact-active .label {
  color: var(--primary-5);
}
.hamburger-menu .label {
  color: var(--neutral-5);
}
.hamburger-menu img {
  margin-right: 16px;
}

.hamburger-menu {
  margin-top: 0px;
  height: 0px;
  background-color: white;
  transition: height 0.2s ease;
  overflow: hidden;
  overflow-y: scroll;
}
.hamburger-menu-after {
  height: 91vh;
  transition: height 0.2s ease;
  z-index: 11;
  position: relative;
}
.hamburger .line {
  width: 26px;
  height: 2px;
  background-color: var(--neutral-5);
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}
.hamburger:hover {
  cursor: pointer;
}

.hamburger.is-active {
  margin-top: 6px;
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}
.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(5px) rotate(45deg);
  -ms-transform: translateY(5px) rotate(45deg);
  -o-transform: translateY(5px) rotate(45deg);
  transform: translateY(5px) rotate(45deg);
}
.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-11px) rotate(-45deg);
  -ms-transform: translateY(-11px) rotate(-45deg);
  -o-transform: translateY(-11px) rotate(-45deg);
  transform: translateY(-11px) rotate(-45deg);
}

.btn:active {
  outline: 0 !important;
  border: 1px solid transparent !important;
}
.btn.show {
  outline: 0 !important;
  border: 1px solid transparent !important;
}

/*header*/
.mobile-header .page {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  color: var(--neutral-8);
}
.mobile-header {
  padding: 20px;
  display: grid;
  width: 100%;
  position: sticky;
  z-index: 10;
  top: 0;
  height: 80px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid transparent;
  border-width: 0px 0px 1px 0px;
  border-image: linear-gradient(
    99.35deg,
    rgba(191, 165, 119, 0.7) 0%,
    rgba(223, 210, 187, 0.7) 8.31%,
    rgba(149, 105, 29, 0.7) 14.58%,
    rgba(223, 210, 187, 0.7) 40.1%,
    rgba(191, 165, 119, 0.7) 66.84%,
    rgba(244, 240, 232, 0.7) 89.12%,
    rgba(149, 105, 29, 0.7) 100%
  );
  border-image-slice: 1;
}
/*footer*/
.mobile-navbar-menu .nav-link.router-link-exact-active img {
  filter: invert(43%) sepia(71%) saturate(2986%) hue-rotate(314deg) brightness(82%) contrast(105%);
}
.mobile-navbar-menu .nav-link.router-link-exact-active {
  color: white;
  font-weight: 500;
  color: var(--primary-5);
}
.mobile-navbar-menu .nav-link.router-link-exact-active {
  background-color: transparent;
}
.mobile-navbar-menu .nav-link img {
  filter: invert(52%) sepia(36%) saturate(209%) hue-rotate(149deg) brightness(86%) contrast(87%);
}
.mobile-navbar-menu .nav-link {
  color: var(--neutral-5);
}

.mobile-navbar-menu {
  position: fixed;
  bottom: 0;
  z-index: 10;
  height: 74px;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95));
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid transparent;
  border-width: 1px 0px 0px 0px;
  border-image: linear-gradient(
    99.35deg,
    rgba(191, 165, 119, 0.7) 0%,
    rgba(223, 210, 187, 0.7) 8.31%,
    rgba(149, 105, 29, 0.7) 14.58%,
    rgba(223, 210, 187, 0.7) 40.1%,
    rgba(191, 165, 119, 0.7) 66.84%,
    rgba(244, 240, 232, 0.7) 89.12%,
    rgba(149, 105, 29, 0.7) 100%
  );
  border-image-slice: 1;
}
</style>
