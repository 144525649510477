<template>
  <Form
    :validation-schema="schema"
    @submit="Submit"
    ref="passwordRecoveryModalRef"
    v-slot="{ errors }"
  >
    <div
      class="modal fade"
      id="passwordRecoveryModal"
      tabindex="-1"
      aria-labelledby="passwordRecoveryModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="heading-06 text-neutral-07 text-center mb-32px">Recuperare parolă</div>
          <div class="modal-body">
            <p class="caption-02 text-neutral-05">
              Introdu mai jos adresa de email aferentă contului tău. <br />Vei primi un email de
              resetare a parolei.
            </p>
            <div class="mb-4" :class="{ 'invalid-input': errors.email_recovery }">
              <label for="email_recovery" class="text-neutral-07 text-button-02 mb-2"
                >Adresa de email</label
              >
              <Field
                v-model="user.Email"
                type="email"
                name="email_recovery"
                id="email_recovery"
                placeholder="Email"
                class="form-control"
                @change="message = ''"
              />
              <ErrorMessage name="email_recovery" class="error-message" />
              <p class="error-message mt-2" v-if="message">{{ message }}</p>
            </div>
            <button class="button btn-medium-wider btn-primary-solid d-block w-100">
              Resetare
            </button>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Field, ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'PasswordRecoveryModal',
  data() {
    return {
      user: {},
      message: '',
    };
  },
  methods: {
    Submit() {
      this.$store.state.loader = true;
      this.$axios
        .post('/api/Auth/forgotPassword', this.user)
        .then(() => {
          this.$utils.ToastNotify('success', 'Email-ul pentru resetarea parolei a fost trimis.');
          // eslint-disable-next-line no-undef
          $('#passwordRecoveryModal').modal('hide');
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.message = 'Ne pare rău, nu se poate realiza recuperarea parolei.';
        });
    },
    ClearModal() {
      this.$refs.passwordRecoveryModalRef.resetForm();
    },
  },
  components: {
    Field,
    ErrorMessage,
    Form,
  },
  computed: {
    schema() {
      return yup.object({
        email_recovery: yup
          .string()
          .email('Email-ul nu este valid.')
          .required('Email-ul este obligatoriu.'),
      });
    },
  },
};
</script>
