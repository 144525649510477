<script>
import googleLoginHandler from '../../utils/google';

export default {
  props: ['test'],
  // data() {
  //   return {
  //     clientId: '23059600989-jkmog9ijch6209mi4v56p7tb9mr51dvk.apps.googleusercontent.com',
  //     backendEndpoint: '/api/Auth/login-google',
  //   };
  // },
  // methods: {
  //   googleLoginHandler(window) {
  //     setGoogleAccounts(window);
  //     const button = getGoogleButton(window.document);
  //     addButtonBehavior(button);
  //   },

  //   setGoogleAccounts(window) {
  //     window.onload = function () {
  //       google.accounts.id.initialize({
  //         client_id: clientId,
  //         callback: handleGoogleUser,
  //       });
  //     };
  //   },

  //   handleGoogleUser(user) {
  //     debugger;
  //     const token = user.credential;
  //     // axios.post(backendEndpoint, {token})
  //     axios.post(`${backendEndpoint}/${token}`);
  //   },

  //   getGoogleButton(document) {
  //     return document.getElementById('google');
  //   },

  //   addButtonBehavior(button) {
  //     button.onclick = function () {
  //       deleteCookie('g_state');
  //       google.accounts.id.prompt();
  //     };
  //   },

  //   deleteCookie(name) {
  //     const expiration = '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  //     document.cookie = name + expiration;
  //   },
  // },
  mounted() {
    googleLoginHandler(window);
  },
};
</script>

<template>
  <button id="google" class="button btn-neutral-outlined justify-content-center" type="button">
    <img src="../../assets/images/icons/gmail-20x20.svg" class="me-2" alt="..." />
    Gmail
  </button>
</template>
