<!-- eslint-disable indent -->
<template>
  <nav class="white-navbar shadow-sm d-none d-sm-block d-xl-none" id="white-navbar-mobile">
    <div class="align-items-center pt-3 row justify-content-around ms-0 me-0">
      <div class="col-auto pe-0 pe-sm-3">
        <div
          class="hamburger"
          :class="{ 'is-active': hamburgerOpen }"
          v-on:click="hamburgerOpen = !hamburgerOpen"
        >
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
      <div class="col-auto pe-0 ps-0 pe-sm-3 ps-sm-3">
        <router-link
          :to="{ name: 'HomePage' }"
          v-on:click="this.$route.name == 'HomePage' ? this.$router.go() : ''"
        >
          <img src="/logo-delicitate.webp" class="logo-img" alt="..." />
        </router-link>
      </div>
      <div class="col-auto d-flex ps-0 ps-sm-3">
        <div v-if="currentUser">
          <button
            type="button"
            class="btn p-0"
            id="dropdownUser"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src="../../assets/images/icons/profile-circle-linear-40x40.svg" alt="..." />
            <span>
              {{ this.$store.state.auth.user.FirstName }}
              {{ this.$store.state.auth.user.LastName }}
            </span>
          </button>

          <ul class="dropdown-menu" aria-labelledby="dropdownUser">
            <li class="w-100">
              <router-link :to="{ name: 'UserProfile' }" class="dropdown-item">
                Profilul utilizatorului
              </router-link>
            </li>
            <li>
              <button type="button" class="dropdown-item" @click="SignOut()">
                Deconectează-te
              </button>
            </li>
          </ul>
        </div>

        <img
          src="../../assets/images/icons/profile-circle-linear-24x24.svg"
          v-if="!currentUser"
          class="pointer float-end"
          id="dropdownLogout"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          alt="..."
        />
        <ul class="dropdown-menu" aria-labelledby="menu-for-login-and-reigster">
          <li>
            <button type="button" class="dropdown-item" @click="$emit('signUp')">
              Înregistrează-te
            </button>
          </li>
          <li>
            <button type="button" class="dropdown-item" @click="$emit('logIn')">
              Autentificare
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="hamburger-menu" :class="{ 'hamburger-menu-after': hamburgerOpen }">
    <hr />
    <div class="row menu-pages mt-4">
      <div class="col-sm-6 mb-3">
        <router-link
          :to="{ name: 'HomePage' }"
          v-on:click="this.$route.name == 'HomePage' ? this.$router.go() : ''"
        >
          Acasă
        </router-link>
      </div>
      <div class="col-sm-6 mb-3">
        <router-link
          :to="{ name: 'Products' }"
          v-on:click="this.$route.name == 'Products' ? this.$router.go() : ''"
        >
          Produse
        </router-link>
      </div>
      <div class="col-sm-6 mb-3">
        <router-link
          :to="{ name: 'Blogs' }"
          v-on:click="this.$route.name == 'Blogs' ? this.$router.go() : ''"
        >
          Articole
        </router-link>
      </div>
      <!-- <div class="col-sm-6 mb-3">
        <router-link
          :to="{ name: 'HomePage' }"
          v-on:click="this.$route.name == 'HomePage' ? this.$router.go() : ''"
        >
          Gift Card
        </router-link>
      </div> -->
      <div class="col-sm-6 mb-3">
        <router-link
          :to="{ name: 'Contact' }"
          v-on:click="this.$route.name == 'Contact' ? this.$router.go() : ''"
        >
          Contact
        </router-link>
      </div>
    </div>
    <hr />
    <div class="row menu-pages mt-4 justify-content-center">
      <div class="col-sm-6 mb-3">
        <router-link
          :to="{ name: 'Cart' }"
          v-on:click="this.$route.name == 'Cart' ? this.$router.go() : ''"
        >
          Coșul de cumpărături
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'navbar-compponent-mobile',
  emits: ['logIn', 'signUp'],
  data() {
    return {
      hamburgerOpen: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-expressions
    this.currentUser;
  },
  methods: {
    SignOut() {
      this.$store.dispatch('auth/logout').then(() => {
        const routeData = this.$router.resolve({ name: 'HomePage' });
        window.open(routeData.href, '_self');
      });
    },
  },
  watch: {
    $route: {
      handler() {
        this.hamburgerOpen = false;
      },
      deep: true,
      immediate: true,
    },

    hamburgerOpen: {
      handler(value) {
        // eslint-disable-next-line no-unused-expressions
        value
          ? ((document.body.style.overflowY = 'hidden'), (document.body.style.position = 'fixed'))
          : ((document.body.style.overflowY = 'visible'),
          (document.body.style.position = 'relative'));
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
.btn:active {
  outline: 0 !important;
  border: 1px solid transparent !important;
}
.btn.show {
  outline: 0 !important;
  border: 1px solid transparent !important;
}

.logo-img {
  max-width: 39vw;
}

.hamburger .line {
  width: 24px;
  height: 2px;
  background-color: var(--neutral-5);
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(3px) rotate(45deg);
  -ms-transform: translateY(3px) rotate(45deg);
  -o-transform: translateY(3px) rotate(45deg);
  transform: translateY(3px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}
.vertical-border {
  display: grid;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #adb5bd;
  height: 20px;
  margin-left: 14px;
  margin-right: 14px;
}

@media (min-width: 576px) {
  .vertical-border {
    margin-left: 24px;
    margin-right: 40px;
  }
}

.hamburger-menu {
  margin-top: -20px;
  height: 0px;
  transition: height 0.2s ease;
  overflow: hidden;
}

.hamburger-menu-after {
  /* height: calc(100vh - 50px); */
  height: 101vh;
  transition: height 0.2s ease;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(20px);
}

.white-navbar {
  position: sticky;
  top: 0;
  height: 80px;
  /* background: rgb(255 255 255 / 70%); */
  background: rgb(255 255 255 / 100%);
  z-index: 55;
  transition: 0.2s;
}

.hamburger-menu h4 {
  margin-left: 5%;
  margin-top: 50px;
}
.hamburger-menu .menu-pages {
  margin-left: 12%;
  margin-right: 12%;
}

.menu-pages {
  list-style-type: none;
  padding-left: 0px;
}

.menu-pages a {
  border: 1px solid #0000001a;
  padding: 8px 32px;
  border-radius: 8px;
  width: 100%;
  display: block;
  transform: none !important;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--neutral-7);
  text-align: center;
}
.menu-pages a.router-link-exact-active {
  border-color: var(--primary-2);
  color: var(--primary-5);
}
</style>
