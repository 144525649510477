<template>
  <div class="dot" :style="{ top: top, right: right }">
    {{ productsCount || 0 }}
  </div>
</template>

<script>
/*eslint-disable*/
export default {
  name: 'ContactView ',
  props: {
    top: {
      type: String,
      default: '0px',
    },
    right: {
      type: String,
      default: '0px',
    },
    isSmall: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      productsCount: 0,
    };
  },
  watch: {
    ['this.$store.state.auth.user']: {
      handler(newValue, oldValue) {
        if (!this.$store.state.auth.user) this.productsCount = 0;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    GetCartProductsNumber() {
      const searchParams = {
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
      };
      this.$axios
        .get(`/api/Order/getCartProductsNumber?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.productsCount = response.data;
        })
        .catch(() => {
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
  },
  mounted() {
    let self = this;
    if (this.$store.state.auth.user) {
      this.GetCartProductsNumber();
      window.addEventListener('CartChangedEventBackend', function () {
        self.GetCartProductsNumber();
      });
    } else {
      const cart = JSON.parse(localStorage.getItem('cart'));
      if (cart && cart.Products) {
        this.productsCount = cart.Products.length;
      } else this.productsCount = 0;
      window.addEventListener('CartChangedEventLocalStorage', function () {
        const cart = JSON.parse(localStorage.getItem('cart'));
        self.productsCount = cart.Products.length || 0;
      });
    }
  },
};
</script>
<style scoped>
.dot {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  font-size: 10px;
  color: white;
  position: absolute;
  background-color: var(--primary-5);
  z-index: 1;
}
</style>
